<div class="layout-wrapper">
  <app-topbar #appTopBar></app-topbar>
  <app-topbar-bot></app-topbar-bot>
  <div class="layout-main">
    <div class="layout-main-content">
      <div class="layout-content" [style.margin-left.px]="!this.appService.navComponentType ? 0 : undefined">
        <router-outlet></router-outlet>
      </div>
    </div>
    <ixToast></ixToast>
  </div>
  <div class="layout-mask" *ngIf="appService.mobileMenuActive"></div>
  <app-footer></app-footer>
</div>
<p-confirmDialog icon="fa fa-question-circle" acceptLabel="Nadaljuj" rejectLabel="Prekliči"></p-confirmDialog>
