import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy
} from '@angular/router';
import { IxReusableComponentBase } from 'ixframework';

// copy of the @angular default as we can no longer use the original directly!
export class DefaultRouteReuseStrategy implements RouteReuseStrategy {
  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  // tslint:disable-next-line:no-empty
  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void {
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig;
  }
}

// in general we use the default strategy, but in some specific areas we want to be more specific!
export class AppRouteReuseStrategy
  extends DefaultRouteReuseStrategy {
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    let reuse = super.shouldReuseRoute(future, curr);
    if (!reuse && future.component === curr.component) {
      // the default strategy wants to recreate the component. If we are sure our component can handle the change
      // allow it to stay in place
      // is it a component or just a name?
      if (typeof future.component === 'function') {
        if (future.component.prototype instanceof IxReusableComponentBase) {
          reuse = true;
        }
      }
    }
    return reuse;
  }
}
