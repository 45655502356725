import { RouterModule, Routes } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
  {
    path: 'vstopnaStran',
    loadChildren: () => import('./vstopnaStran/vstopnaStran.module').then(m => m.VstopnaStranModule)
  },
  {
    path: 'pravnePodlage',
    loadChildren: () => import('./pravnePodlage/pravnePodlage.module').then(m => m.PravnePodlageModule)
  },
  {
    path: 'politikaZasebnosti',
    loadChildren: () => import('./politikaZasebnosti/politikaZasebnosti.module').then(m => m.PolitikaZasebnostiModule)
  },
  {
    path: 'kontakt',
    loadChildren: () => import('./kontakt/kontakt.module').then(m => m.KontaktModule)
  },
  {
    path: 'obvNedelovanjeApk',
    loadChildren: () => import('./obvNedelovanjeApk/obvNedelovanjeApk.module').then(m => m.ObvNedelovanjeApkModule)
  },
  {
    path: 'zacetnaStran',
    loadChildren: () => import('./zacetnaStran/zacetnaStran.module').then(m => m.ZacetnaStranModule)
  },
  {
    path: '',
    redirectTo: 'vstopnaStran',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: '/404'
  }
];

export const AppRoutes: ModuleWithProviders<RouterModule> = RouterModule.forRoot(routes);
