/**
 * Angular bootstrapping
 */
import { enableProdMode, NgModuleRef } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'chart.js/dist/Chart.min.js';
import 'chartjs-plugin-zoom/chartjs-plugin-zoom.min.js';

/**
 * App Module
 * our top level module that holds all of our components
 */
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { IxEvtKeycloakHelperService } from './app/util/ixEvtKeycloakHelper.service';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';

if (environment.production) {
  enableProdMode();
}

/**
 * Bootstrap our Angular app with a top level NgModule
 */
export function main() {
  // ixtlan Keycloak
  // IXTLAN Upgrade: Odkomentiraj keycloak avtentikacijo in nastavi url do rest servisa
  IxKeycloakService.controllerUrl = '/api/evt';
  IxKeycloakService.realm = 'arsktrp';
  IxKeycloakService.useLogoutPage = false;
  // IxKeycloakService.enableProfiles();
  IxKeycloakService.enableRoleResource('evt');
  // mainBootstrap();
  IxEvtKeycloakHelperService.initWithoutLogin()
    .then(() => {
      mainBootstrap();
    })
    .catch(err => {
      // tslint:disable-next-line:no-console
      console.error(err);
      window.location.href = '/error.html';
    });
}

function mainBootstrap(): Promise<any> {
  let modulePromise: Promise<NgModuleRef<AppModule>> | null = null;

  modulePromise = platformBrowserDynamic().bootstrapModule(AppModule);
  // tslint:disable-next-line:no-console
  return modulePromise.catch((err) => console.error(err));
}

/**
 * Needed for hmr
 * in prod this is replace for document ready
 */
switch (document.readyState) {
  case 'loading':
    document.addEventListener('DOMContentLoaded', _domReadyHandler, false);
    break;
  case 'interactive':
  case 'complete':
  default:
    main();
}

function _domReadyHandler() {
  document.removeEventListener('DOMContentLoaded', _domReadyHandler, false);
  main();
}
