import { IxFrameworkModule, IxFrameworkRoutes, IxKeycloakHttpInterceptor, IxUrlSerializer } from 'ixframework';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { AppService } from './app.service';
import { AppRouteReuseStrategy } from './app.strategy';
import { AppTopBarComponent } from './app.topbar.component';
import { AppRoutes } from './app.routes';
import { ComponentModule, ComVizitkaService } from '@arsktrp-shared/components';
import { SharedModule } from './shared/common/shared.module';
import { AppFooterComponent } from './app.footer.component';
import { AppTopBarBotComponent } from './app.topbarBot.component';
import { EvtVizitkaService } from './shared/common/evtVizitika/evtVizitka.service';
import { AppGuard } from './app.guard';
import { AppPublicGuard } from './app.public.guard';
import { DialogService } from 'primeng';
import { IxEvtKeycloakHelperService } from './util/ixEvtKeycloakHelper.service';

// @ts-ignore
@NgModule({
  id: 'appModule',
  imports: [
    BrowserAnimationsModule,
    IxFrameworkModule.forRoot(),
    FormsModule,
    AppRoutes,
    HttpClientModule,
    IxFrameworkRoutes,
    ComponentModule.forRoot(),
    SharedModule.forRoot()
  ],
  declarations: [
    AppComponent,
    AppTopBarComponent,
    AppTopBarBotComponent,
    AppFooterComponent
  ],
  providers: [
    AppService,
    EvtVizitkaService,
    ComVizitkaService,
    AppGuard,
    AppPublicGuard,
    IxEvtKeycloakHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useExisting: IxKeycloakHttpInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: AppRouteReuseStrategy
    },
    {provide: UrlSerializer, useClass: IxUrlSerializer},
    DialogService,
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {

}
