import {Component, Injector} from '@angular/core';
import {IxParentDTO, IxServiceBaseUtil} from 'ixframework';
import {PostavitevDTO} from './postavitevInfo.model';

@Component({
  selector: 'postavitevInfo',
  templateUrl: './postavitevInfo.component.html',
})
export class PostavitevInfoComponent {
  private readService: IxServiceBaseUtil;
  public barva: string;
  public besedilo: string;
  constructor(private injector: Injector, ) {
    this.readService = new IxServiceBaseUtil(this.injector, '/api/evt/postavitevInfo');
    this.readService.get<IxParentDTO<PostavitevDTO>>('readPostavitev').subscribe(data => {
      this.barva = data.row.barva;
      this.besedilo = data.row.besedilo;
    });
  }
}
