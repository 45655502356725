import { Injectable, Injector, OnDestroy } from '@angular/core';
import { ComAppService } from '@arsktrp-shared/components';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';

@Injectable()
export class AppService extends ComAppService implements OnDestroy {

  constructor(
    protected injector: Injector,
  ) {
    super(injector);
    this.controllerUrl = IxKeycloakService.controllerUrl;
    this.version = '1.1.0.0.0';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
