import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppService } from './app.service';
import { Observable, of, Subscription, zip } from 'rxjs';
import { map } from 'rxjs/operators';
import { IxCanDeactivateInterface } from 'ixframework/component/ixCanDeactivate.interface';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';
import { ConfirmationService } from 'primeng/api';

@Injectable()
export class AppGuard implements CanActivate, CanDeactivate<IxCanDeactivateInterface> {

  private subscriptionHasAccess?: Subscription;

  constructor(private appService: AppService,
              private confirmationService: ConfirmationService,
              private ixKeycloakService: IxKeycloakService,
              private router: Router,
  ) {
  }

  fireAccessDenied() {
    document.location.href = `${this.appService.contextRoot}/access.html`;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.subscriptionHasAccess) {
      this.subscriptionHasAccess.unsubscribe();
    }

    // ce uporabnik ni prijavljen potem dostopa ne varujemo z router sistemom temveč keycloak.js. Zato router dovoli dostop
    if (!this.ixKeycloakService.isLoggedIn) {
      this.router.navigate(['vstopnaStran']);
      return of(false);
    }
    if (state.url === '/404') {
      window.location.href = `${this.appService.contextRoot}/404.html`;
      return of(false);
    }
    const observableSeznam: any[] = [];

    // If we dont have the basic EVT role redirect to access denied
    if (!this.ixKeycloakService.isInRole('EVT_USER')) {
      document.location.href = '/access.html';
      return of(false);
    }

    // Preverimo pravice za dostop
    if (route.data.role && !this.ixKeycloakService.isInRole(route.data.role)) {
      this.fireAccessDenied();
      return of(false);
    }

    if (route.data.objectCode) {
      // preverimo, če ima uporabnik potrebno pravico in akcijo za dostop do route
      const hasAccess: Observable<boolean> = this.appService.hasAccess(route.data.objectCode, route.data.action);
      observableSeznam.push(hasAccess);
      this.subscriptionHasAccess = hasAccess.subscribe(
        (x: boolean) => {
          if (x === false) {
            // če uporabnik nima dostopa potem preusmerimo na namizje
            // v app.routes.ts je potrebno še za path: '', naštimati preusmeritev na component: AppNamizjeComponent
            // tako bo uporabnik lahko prišel nazaj v aplikacijo do strani z iskalnikom
            this.fireAccessDenied();
          }
        }
      );

    }

    if (route.data.publicService !== true && !route.data.role && !route.data.objectCode) {
      this.fireAccessDenied();
      return of(false);
    }
    if (observableSeznam.length === 0) {
      return of(true);
    } else {
      return zip(...observableSeznam)
        .pipe(
          map(x =>
            x.every(y => y === true)
          )
        );
    }
  }

  canDeactivate(
    component: IxCanDeactivateInterface,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.canDeactivate) {
      return true;
    }

    if (!component.canDeactivate(state, nextState)) {
      // Otherwise ask the user with the confirmationService and return its
      // promise which resolves to true or false when the user decides
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Zapuščanje strani',
          message: 'Ali želite zapustiti stran brez shranjevanja?',
          acceptLabel: 'Nadaljuj brez shranjevanja',
          rejectLabel: 'Ostani na strani',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    }
    return true;
  }
}
