import { ModuleWithProviders, NgModule } from '@angular/core';
import { PostavitevInfoComponent } from './postavitevInfo/postavitevInfo.component';
import { EvtSklopComponent } from './evtSklop/evtSklop.component';
import { EvtAplikacijaComponent } from './evtAplikacija/evtAplikacija.component';
import { CommonModule } from '@angular/common';
import { EvtAplikacijskaSkupinaComponent } from './evtAplikacijskaSkupina/evtAplikacijskaSkupina.component';
import { EvtVizitkaService } from './evtVizitika/evtVizitka.service';

@NgModule({
  declarations: [
    PostavitevInfoComponent,
    EvtSklopComponent,
    EvtAplikacijaComponent,
    EvtAplikacijskaSkupinaComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PostavitevInfoComponent,
    EvtSklopComponent,
    EvtAplikacijaComponent,
    EvtAplikacijskaSkupinaComponent,
  ]

})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule
    };
  }
}
