import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot } from '@angular/router';
import { IxCanDeactivateInterface } from 'ixframework/component/ixCanDeactivate.interface';
import { Observable, of, Subscription } from 'rxjs';
import { AppService } from './app.service';
import { ConfirmationService } from 'primeng/api';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';

@Injectable()
export class AppPublicGuard implements CanActivate, CanDeactivate<IxCanDeactivateInterface> {

  private subscriptionHasAccess?: Subscription;

  constructor(private appService: AppService,
              private confirmationService: ConfirmationService,
              private ixKeycloakService: IxKeycloakService,
              private router: Router,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    if (this.subscriptionHasAccess) {
      this.subscriptionHasAccess.unsubscribe();
    }

    if (state.url === '/obvNedelovanjeApk'
      || state.url === '/politikaZasebnosti'
      || state.url === '/pravnePodlage'
      || state.url === '/kontakt') {
      return of(true);
    }
    if (this.ixKeycloakService.isLoggedIn) {
      this.router.navigate(['zacetnaStran']);
      return of(false);
    }
    return of(true);
  }

  canDeactivate(
    component: IxCanDeactivateInterface,
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!component.canDeactivate) {
      return true;
    }

    if (!component.canDeactivate(state, nextState)) {
      // Otherwise ask the user with the confirmationService and return its
      // promise which resolves to true or false when the user decides
      return new Promise((resolve, reject) => {
        this.confirmationService.confirm({
          header: 'Zapuščanje strani',
          message: 'Ali želite zapustiti stran brez shranjevanja?',
          acceptLabel: 'Nadaljuj brez shranjevanja',
          rejectLabel: 'Ostani na strani',
          accept: () => {
            resolve(true);
          },
          reject: () => {
            resolve(false);
          }
        });
      });
    }
    return true;
  }
}
