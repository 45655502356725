import { AppService } from './app.service';
import { IxKeyboardShortcutsService, IxMenuItem } from 'ixframework';
import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppTopBarComponent } from './app.topbar.component';
import { PRIORITY_GLOBAL, ShortcutBindings } from 'ixframework/shortcuts/ixKeyboardShortcuts.service';

export const ROOT_SELECTOR = 'app-root';

@Component({
  selector: ROOT_SELECTOR,
  templateUrl: './app.component.html'
})
export class AppComponent implements AfterViewInit, OnInit {

  @ViewChild('appTopBar')
  public appTopBar?: AppTopBarComponent;

  layout: string = 'default';

  // tslint:disable-next-line:ban-types
  documentClickListener?: Function;

  constructor(
    public renderer: Renderer2,
    public appService: AppService,
    private titleService: Title,
    private ixKeyboardShortcutsService: IxKeyboardShortcutsService,
  ) {
    this.titleService.setTitle('ekmetijstvo');
    this.appService.breadcrumbSubject.subscribe((breadcrumb) => {
      if (breadcrumb !== null && breadcrumb.length > 1 && breadcrumb[1] !== undefined) {
        this.titleService.setTitle('Arsktrp ' + breadcrumb[1].label);
      } else {
        this.titleService.setTitle('ekmetijstvo');
      }
    });
    this.appService.breadcrumbClick.subscribe((params: { ixMenuItem: IxMenuItem, level: number }) => {
      if (!params.ixMenuItem.routerLink && params.level === 0) {
        this.appService.appMenuOpen = true;
      }
    });
  }

  ngOnInit(): void {
    const ks = this.ixKeyboardShortcutsService;
    const b: ShortcutBindings = {};
    const openMainMenu = ks.userShortcuts.openMainMenu;
    const search = ks.userShortcuts.search;
    const newDocument = ks.userShortcuts.newDocument;
    const shortcuts = ks.userShortcuts.shortcuts;
    const focusNext = ks.userShortcuts.focusNext;
    const toggleSideMenu = ks.userShortcuts.toggleSideMenu;

    ks.addShortcut(b, openMainMenu, (e: KeyboardEvent): any => {
      if (!IxKeyboardShortcutsService.isEventFromInput(e)) {
        this.appService.navUp = false;
        this.appService.appMenuOpen = true;
        e.preventDefault();
        return false;
      }
    });
    ks.addShortcut(b, search, (e: KeyboardEvent): any => {
      if (!IxKeyboardShortcutsService.isEventFromInput(e)) {
        if (this.appTopBar && this.appTopBar.inputSearch) {
          this.appService.navUp = false;
          setTimeout(() => {
            this.appTopBar.inputSearch.nativeElement.focus();
          }, 0);
        }
        e.preventDefault();
        return false;
      }
    });
    ks.addShortcut(b, shortcuts, (e: KeyboardEvent): any => {
      if (!IxKeyboardShortcutsService.isEventFromInput(e)) {
        this.appService.appShortcutsOpen = !this.appService.appShortcutsOpen;
        e.preventDefault();
        return false;
      }
    });
    ks.addShortcut(b, focusNext, (e: KeyboardEvent): any => {
      IxKeyboardShortcutsService.focusNextInput(e);
      return true;
    });
    ks.addShortcut(b, toggleSideMenu, (e: KeyboardEvent): any => {
      this.appService.onMenuButtonClick(e);
      return false;
    });

    ks.listenShortcut(b, {
        priority: PRIORITY_GLOBAL,
        // Optional configurations.
        terminal: true, // Default match.
        inputs: true // Default false.
      }
    );
  }

  ngAfterViewInit() {
    // hides the horizontal submenus or top menu if outside is clicked
    this.documentClickListener = this.renderer.listen('body', 'click', (event: Event) => {
      if (!this.appService.menuClick && !this.appService.menuButtonClick) {
        this.appService.mobileMenuActive = false;
        this.appService.overlayMenuActive = false;
        this.appService.resetSlim = true;
      }

      if (!this.appService.topbarMenuClick && !this.appService.topbarMenuButtonClick) {
        this.appService.topbarMenuActive = false;
      }

      this.appService.menuClick = false;
      this.appService.menuButtonClick = false;
      this.appService.topbarMenuClick = false;
      this.appService.topbarMenuButtonClick = false;
    });
  }

}
