import { Component, ElementRef, ViewChild } from '@angular/core';
import { AppService } from './app.service';

@Component({
  selector: 'app-topbar-bot',
  templateUrl: './app.topbarBot.component.html'
})
export class AppTopBarBotComponent {
  @ViewChild('inputSearch')
  public inputSearch?: ElementRef;

  constructor(
    public appService: AppService
  ) {
  }
}
