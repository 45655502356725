import { Component, Injector } from '@angular/core';
import { AppService } from './app.service';
import { IxComponentBase } from 'ixframework/component/ixComponentBase';

@Component({
  selector: 'app-footer',
  template: `
    <div class="footer">
      <div class="card clearfix evtFooter">
        <div class="evtFooterContent">
          <!-- POMEMBNO: Ne izbrisemo komentarja zaradi lazjega prevzema novejse verzije Primeng Ultima
          <span class="footer-text-left">PrimeNG ULTIMA for Angular 2</span>
          -->
          <!-- IXTLAN Upgrade: Nastavi narocnika -->
          <!-- <span class="footer-text-left"><a [routerLink]="['/vizitka']">MFERAC</a></span> -->
          <!-- IXTLAN Upgrade: Nastavi avtorsko pravico -->
          <div class="evtFooterARSKTRPLink">
            <a href="https://www.gov.si/drzavni-organi/organi-v-sestavi/agencija-za-kmetijske-trge-in-razvoj-podezelja/"><span class="far fa-fw fa-copyright"></span> 2023 Agencija Republike slovenije
              <br> za kmetijske trge in razvoj
              podeželja</a>
          </div>

          <div class="evtFooterPolitika">
            <a [routerLink]="['/politikaZasebnosti']"> Politika zasebnosti </a>
          </div>

          <div class="evtFooterPravne" >
            <a [routerLink]="['/pravnePodlage']"> Pravne podlage </a>
          </div>

          <div class="evtFooterKontakt">
            <a [routerLink]="['/kontakt']"> Kontakt </a>
          </div>

          <div class="evtFooterARSKTRPIcon">
            <img src="assets/layout/images/footer/arsktrpIcon.png">
          </div>

        </div>
        <!--<img src="./assets/layout/images/Logo_EKP_socialni_sklad_SLO_slogan.jpg" style="max-width: 180px; width: 100%; margin-left: -5px;" >-->
      </div>
    </div>
  `
})
export class AppFooterComponent extends IxComponentBase {
  constructor(
    public injector: Injector,
    public appService: AppService
  ) {
    super();
  }
}
