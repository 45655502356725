import { Injectable } from '@angular/core';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';
import * as _Keycloak from 'keycloak-js';


const Keycloak = _Keycloak;

@Injectable()
export class IxEvtKeycloakHelperService {
  static initWithoutLogin(): Promise<any> {
    IxKeycloakService.log('IxKeycloakService initializing');
    const url = 'keycloakJson';
    const contextRoot = document.getElementsByTagName('base')[0].getAttribute('href');
    const config = IxKeycloakService.serviceUrl ? IxKeycloakService.serviceUrl + url : undefined;
    const keycloakInstance = Keycloak(config);
    IxKeycloakService.auth.loggedIn = false;

    return new Promise((resolve, reject) => {
      const tokenCache = IxKeycloakService.getTokenFromSessionStorage();
      keycloakInstance
        .init({ token: tokenCache.token, refreshToken: tokenCache.refreshToken })
        .then(() => {
          IxKeycloakService.auth.authz = keycloakInstance;
          if (tokenCache?.token && tokenCache?.refreshToken) {
            IxKeycloakService.auth.loggedIn = true;
            IxKeycloakService.auth.authz.token = tokenCache.token;
            IxKeycloakService.auth.authz.refreshToken = tokenCache.refreshToken;
          }
          if (IxKeycloakService?.auth?.authz?.token && IxKeycloakService?.auth?.authz?.refreshToken) {
            IxKeycloakService.auth.loggedIn = true;
          }
          IxKeycloakService.auth.logoutBase =
            keycloakInstance.authServerUrl +
            `/realms/${keycloakInstance.realm}/protocol/openid-connect/logout?redirect_uri=`;
          IxKeycloakService.saveTokenToSessionStorage();
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  }
}
