import { Component, ElementRef, isDevMode, ViewChild } from '@angular/core';
import { AppService } from './app.service';
import { EvtVizitkaService } from './shared/common/evtVizitika/evtVizitka.service';
import { IxKeycloakService } from 'ixframework/ixKeycloak.service';
import { ComVizitkaService } from '@arsktrp-shared/components/index';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent {
  @ViewChild('inputSearch')
  public inputSearch?: ElementRef;

  constructor(
    public appService: AppService,
    public evtVizitkaService: EvtVizitkaService,
    private ixKeycloakService: IxKeycloakService,
    public comVizitkaService: ComVizitkaService
  ) {
  }

  public isPrijavljen(): boolean {
    return this.ixKeycloakService?.isLoggedIn;
  }

  public login() {
    const keycloakInstance = IxKeycloakService?.auth?.authz;
    if (keycloakInstance) {
      keycloakInstance.login();
    }
  }

  public logout(): boolean {
    IxKeycloakService.removeTokenFromSessionStorage();
    if (!IxKeycloakService.logoutRedirectUri) {
      if (IxKeycloakService.useLogoutPage === true) {
        if (isDevMode()) {
          IxKeycloakService.logoutRedirectUri = window.location.origin + '/vstopnaStran';
        } else {
          IxKeycloakService.logoutRedirectUri = window.location.origin + '/ui/evt/vstopnaStran';
        }
      } else {
        IxKeycloakService.logoutRedirectUri = window.location.href;
      }
    }
    const redirect = IxKeycloakService.auth.logoutBase + IxKeycloakService.logoutRedirectUri;
    IxKeycloakService.auth.loggedIn = false;
    IxKeycloakService.auth.authz = undefined;
    IxKeycloakService.auth.logoutBase = undefined;

    if (redirect) {
      window.location.href = encodeURI(redirect);
    }
    return false;
  }
}
