<div class="topbar">

  <div class="topbar-top">
    <img class="govLogo" src="assets/layout/images/topbar/govLogo.png">
    <button *ngIf="!this.isPrijavljen(); else topbarIkone;" class="ui-button ui-widget evt-button" (click)="this.login()">
      <span class="ui-button-text ui-c">Prijava</span>
    </button>

    <ng-template #topbarIkone>
      <div class="topbar-ikone">
        <div *ngIf="this.evtVizitkaService?.data?.row?.uporabnik" id="uporabnik" style="text-align:right; margin-right:5px">
          {{this.evtVizitkaService?.data?.row?.uporabnik}}
        </div>
        <a #home style="padding-bottom: 1px" href="#" [routerLink]="this.appService.logoRouterLink">
          <i class="fa fa-fw fa-home fa-lg"></i>
        </a>
<!--        <a #sporocila style="display: inline-block;" href="#">-->
<!--          <i class="fa fa-fw fa-envelope fa-lg"></i>-->
<!--        </a>-->
        <a #userIcon style="display: inline-block; padding-bottom: 3px" href="#" (click)="this.appService.onTopbarMenuButtonClick($event)">
          <i class="fa fa-fw fa-user fa-lg"></i>
        </a>
      </div>
    </ng-template>
  </div>

  <ul id="topbar-menu" class="fadeInDown" [ngClass]="{'topbar-menu-visible': this.appService.topbarMenuActive}"
      (click)="this.appService.onTopbarMenuClick($event);">
    <li #profile [ngClass]="{'menuitem-active':this.appService.activeTopbarItem === profile}">
      <ul>
        <li role="menuitem">
          <a class="disabled" style="padding-left: 16px;background-color: rgba(239, 229, 225, 0.5)">
            <i class="fa fa-fw fa-cogs"></i>
            <span>Osebne nastavitve</span>
          </a>
        </li>

        <li role="menuitem">
          <a href="#" (click)="this.logout()" style="padding-left: 16px">
            <i class="fa fa-fw fa-sign-out"></i>
            <span>Odjava</span>
          </a>
        </li>
      </ul>
    </li>


  </ul>

</div>
