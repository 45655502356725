import { EvtVizitkaDTO } from './evtVizitka.model';
import { Injectable, Injector } from '@angular/core';
import { IxParentDTO } from 'ixframework/index';
import { IxServiceBaseUtil } from 'ixframework/util/ixServiceBaseUtil';

@Injectable()
export class EvtVizitkaService {

  private readService: IxServiceBaseUtil;
  public data: IxParentDTO<EvtVizitkaDTO>;
  private serviceUrl: string = '/api/evt/Vizitka';

  constructor(
    public injector: Injector,
  ) {
    this.readService = new IxServiceBaseUtil(this.injector, this.serviceUrl);

    this.readVizitka();
  }

  readVizitka() {
    this.readService.safeCall((ignoreWarnings) =>
        this.readService.get('readVizitka', undefined),
      (result: IxParentDTO<EvtVizitkaDTO> | undefined | null) => {
        if (result) {
          this.data = result;
        }
      }
    );
  }
}
